@import "../styles/mixins";

.card-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: left;
    gap: 2rem;

    @include respond(tab-port) {
        padding: 2rem;
    }

}