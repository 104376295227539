@import "../styles/variables";

.resume{
    display: flex;
    height: 100%;

    &__detail{
        margin-top: 3rem;
    }
    
    &__left{
        width: 50%;
        padding-left: 3.5rem;
        background-color: $color-gray-dark;
    }

    &__right{
        width: 50%;
        background-size: cover;
    }
}