@import "../styles/variables";
@import "../styles/mixins";

.navigation {
    position: fixed;
    height: 100vh;
    width: 3.5rem;
    background-color: $color-black;
    z-index: 99;

    @include respond(tab-port){
        height: 3.5rem;
        width: 100%;
        bottom: 0;
    }
    
    &__list {
        width: 100%;
        list-style-type: none;
        padding-left: 0;
        text-align: center;
        
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        @include respond(tab-port){
            flex-direction: row; 
            justify-content: center;
            justify-content: space-around;
        }
    }

    &__item {
        width: 3.5rem;
        height: 3.5rem;
        &:hover {
                background-color: $color-primary-dark;
        }
    }

    &__icon {
        font-size: 1.5rem;
        line-height: 2.5;
        color: $color-white;
        display: block;
        height: 3.5rem;
    }
}