@import "../styles/variables";
@import "../styles/mixins";

.description--1 {
    padding: 0 3rem;
    font-size: 1.5rem;
    hyphens: auto;
    font-weight: 100;
    text-align: center;
    color: $color-white;

    @include respond(tab-port) {
        font-size: 1.2rem;
        padding: 3rem 5rem;
    }
    
        @include respond(phone) {
            font-size: 1.1rem;
            padding: 3rem 3rem;
        }
}