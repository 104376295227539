@import "../styles/variables";
@import "../styles/mixins";

.cover {
    height: 100vh;
    background-color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	overflow: hidden;
	position: relative;
}

.cover__video{
	position: absolute;
	width: 100%;
	height: auto;
	object-fit: cover;
}