.spinny-loader {
	display: inline-block;
	position: relative;
	width: 140px;
	height: 140px;
	margin: 0 10px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;

		border-radius: 200px;
		border-style: solid;
		border-width: 15px;
		border-color: #445878 transparent #445878 transparent;

		animation-name: spinny;
		animation-duration: 0.6s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}
	&::after {
		width: 140px;
		height: 140px;
		animation-direction: alternate-reverse;
	}
	
	&::before {
		width: 100px;
		height: 100px;
	}
}


.spinny-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 60px;
	height: 60px;
	transform: translate3d(-50%, -50%, 0);
	border-radius: 200px;
	background-color: #445878;
}


@keyframes spinny {
	0% {
		transform-origin: 50%;
		transform: translate3d(-50%, -50%, 0) rotateZ(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotateZ(360deg);
	}
}
