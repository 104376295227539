// responsive mixin
@mixin respond($breakpoint) {
	@if $breakpoint ==phone {
		@media only screen and (max-width: 37.5em) {
			@content;
		}
	}

	@if $breakpoint ==tab-port {
		@media only screen and (max-width: 56.25em) {
			@content;
		}
	}

	@if $breakpoint ==tab-land {
		@media only screen and (max-width: 75em) {
			@content;
		}
	}

	@if $breakpoint ==big {
		@media only screen and (min-width: 112.5em) {
			@content;
		}
	}
}
