@import "../styles/variables";


.information{
    width: 50%;
    padding: 1rem 3rem;
    &__title{
        color: $color-gray-light;
    }
    &__sub{
        font-size: .8rem;
        padding-bottom: 1rem;
        color: $color-gray-light;
    }
    &__des{
        text-align: justify;
        hyphens: auto;
    }
}