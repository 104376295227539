@import "../styles/variables";
@import "../styles/mixins";

.title--2 {
    font-size: 1.5rem;
    color: $color-primary;
    padding: 2rem 3rem;

    @include respond(tab-port) {
            text-align: center;
    }
}