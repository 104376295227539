@import "../styles/variables";
@import "../styles/mixins";

.container--left {
    width: 60%;
    padding-left: 3.5rem;
    background-color: $color-gray-dark;
    padding-bottom: 3.5rem;
	height: 100vh;
	overflow-y: scroll;

    @include respond(tab-port) {
        width: 100%;
        padding-left: 0;
    }
}