@import "../styles/variables";

.skillcard {
    &__title{
        color: $color-gray;
        padding: .5rem 0;
    }

    &__icon {
        font-size: 2.5rem;
        color: $color-primary;
    }
    &__des{
        text-align: justify;
        hyphens: auto;
    }
}