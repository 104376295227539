@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import "./styles/variables";
@import "./styles/mixins";
@import "react-calendar-heatmap/dist/styles.css";

body {
	font-family: "Poppins", sans-serif;
	color: $color-gray;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #9d00c952 #dedede12;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #dedede12;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(157, 0, 201, 0.322);
	border-radius: 10px;
	border: 1px solid #ffffff60;
}
