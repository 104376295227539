@import "../styles/variables";

.btn {
    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: .6rem 1rem;
        margin: 2rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all 0.2s;
        position: relative;
        background-color: $color-primary;
        color: $color-white;
        border: none;
        cursor: pointer;
    }

    &:hover {
        transform: translateY(-0.3rem);
        box-shadow: 0 1rem 2rem rgba($color-primary, 0.2);
    }

    &:active,
    &:focus {
        outline: none;
        transform: translateY(-0.1rem);
        box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
    }
}