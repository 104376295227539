@import "../styles/variables";

.react-calendar-heatmap {
	.color-scale-0{
		fill: rgba($color-primary, 0.1);
	}
	.color-scale-1 {
		fill: rgba($color-primary, 0.5);
	}
	.color-scale-2 {
		fill: rgba($color-primary, 0.7);
	}
	.color-scale-3 {
		fill: rgba($color-primary, 0.9);
	}
	.color-scale-4 {
		fill: $color-primary;
	}
	.color-scale-5{
		fill: $color-primary;
	}
}